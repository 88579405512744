import { useQuery } from '@tanstack/react-query';
import { fetchUserProfile } from 'services';

export function useOfficeUserProfile(userId: string) {
  const query = useQuery({
    queryKey: ['pmi.web.office__office_user_profile', userId],
    queryFn: () => fetchUserProfile(userId),
    staleTime: 900000,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

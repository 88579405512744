import { BottomSheetContainer } from 'components/modals';
import { useOfficeUserProfile } from 'hooks';

import { UserHeader } from './headers';
import { ProfileDetails } from './ProfileDetails';
import { LoadingSpinner } from './spinners';

interface IProfileProps {
  readonly open: boolean;
  readonly onDismiss: () => void;
  readonly userId: string;
}

export function Profile(props: IProfileProps) {
  const { data: userProfile, isLoading } = useOfficeUserProfile(props.userId);

  return (
    <BottomSheetContainer
      open={props.open}
      onDismiss={props.onDismiss}
      header={
        isLoading || !userProfile ? null : (
          <div
            className={`bg-secondary px-sm md:px-md py-md md:py-lg flex flex-col items-center`}
          >
            <UserHeader
              user={userProfile}
              huge
              showBadges={false}
              showCheckAssurance={false}
              showQualification={false}
              showCountryFlag={false}
            />
          </div>
        )
      }
    >
      <section>
        {isLoading && (
          <div className="w-full flex justify-center p-md">
            <LoadingSpinner smallSize />
          </div>
        )}
        {!isLoading && userProfile && <ProfileDetails {...userProfile} />}
      </section>
    </BottomSheetContainer>
  );
}
